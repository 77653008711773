import Layout from "../layout"
import { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Link } from "react-router-dom"
import { images, CustomImage } from "./images";


const slides = images.map(({ original, width, height }) => ({
    src: original,
    width,
    height,
}));

function GalleryC() {
    const [index, setIndex] = useState(-1);

    const handleClick = (index: number, item: CustomImage) => setIndex(index);
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])

    return (
        <Layout activePage='gallery'>
            <main className="page">
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Gallery</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="about">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-12'>


                                <Gallery
                                    images={images}
                                    onClick={handleClick}
                                    enableImageSelection={false}
                                />
                                <Lightbox
                                    slides={slides}
                                    open={index >= 0}
                                    index={index}
                                    close={() => setIndex(-1)}
                                />
                            </div>

                        </div>

                        <div className="row mt-5">
                            <div className="col">
                                <p>For more images and videos please visit our facebook page <a href="https://www.facebook.com/satimatasheeladevi" target="_blank">here</a></p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout >
    )
}
export default GalleryC