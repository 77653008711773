
import { Link } from "react-router-dom"
export default function Footer() {
    return (

        <>
            <footer id="footer" className="footer">

                <div className="container">
                    <div className="row gy-3">
                        <div className="col-lg-3 col-md-6 d-flex">
                            <i className="bi bi-geo-alt icon"></i>
                            <div>
                                <h4>Locate Us</h4>
                                <p>
                                    Mandir Sati Mata Sheela Devi, Thathari Gate, Batala, Punjab 143505<br />
                                    <a href="https://www.google.com/maps/dir//Sati+Mata+Sheela+Devi+Mandir+Sati+Mata+Sheela+Devi+Thathari+Gate+Batala,+Punjab+143505/@31.8065919,75.2013,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x391bc25600160735:0xf75a182b770fd7a8" target="_blank" className="btn btn-danger btn-small mt-2">Map Directions</a>
                                </p>
                            </div>

                        </div>

                        <div className="col-lg-3 col-md-6 footer-links d-flex">
                            <i className="bi bi-house icon"></i>
                            <div>
                                <h4>Quick Link</h4>
                                <p>
                                    <Link className="text-white" to="/">Home</Link><br />
                                    <Link className="text-white" to="/about-us">About Us</Link><br />

                                    <Link className="text-white" to="/online-donation">Online Donation</Link><br />
                                    <Link className="text-white" to="/gallery">Gallery</Link><br />
                                    <Link className="text-white" to="/contact-us">Contact Us</Link><br />

                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links d-flex">
                            <i className="bi bi-clock icon"></i>
                            <div>
                                <h4>Opening Hours</h4>
                                <p>
                                    <strong>Mon-Sun: 7AM</strong> - 9PM<br />
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Follow Us</h4>
                            <div className="social-links d-flex">
                                <a href="https://www.facebook.com/satimatasheeladevi/" target="_blank" className="facebook"><i className="bi bi-facebook"></i></a>
                                <a href="https://instagram.com/satimatasheeladevi" className="instagram" target="_blank"><i className="bi bi-instagram"></i></a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Akhil Beotra Mahajan Sabha (Reg.)</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">


                    </div>
                </div>

            </footer>

            <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

            {/* <div id="preloader" style={{ backgroundColor: "#ccc" }}></div> */}
        </>
    )
}