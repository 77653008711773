import { useEffect } from "react"
import Layout from "../layout"
import { Link } from "react-router-dom"

function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])
    return (
        <Layout activePage='about'>
            <main className="page">
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>About Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="about">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-9'>

                                <p><strong>About Us: Akhil Beotra Mahajan Sabha</strong></p>

                                <p>
                                    <strong>A Legacy Woven in Time: 400-500 Years of Beotra Heritage</strong> </p>
                                <p>
                                    Akhil Beotra Mahajan Sabha has a rich history dating back 400-500 years when our journey began in Pakistan. In the Kingra village, we had a
                                    temple that stood as a symbol of our cultural heritage. However, due to the partition of the country, our temple
                                    remained in Pakistan. In 1949, four founding members of our Sabha—Late Sh. Tirath Ram Mahajan, Sh. Mehnga Ram Mahajan,
                                    Sh. Karam Chand Mahajan, and Sh. Shiv Deyal Mahajan (Shiba)—traveled to our ancestral land. They brought sacred water
                                    ("jal") and soil ("mohre") from the original temple site in Pakistan.</p>

                                <p>
                                    <strong>Journey to Preserve Tradition: Founding Members and the Establishment of the Batala Temple</strong></p>

                                <p>
                                    Upon their return to Batala, Punjab, our Sabha established a new temple (Beotra Hall), where the sacred water was ceremonially placed
                                    in a well, and the soil was enshrined in the temple's sanctum.This temple became a beacon, a testament to our resilience and a symbol of our cultural identity.</p>

                                <p>
                                    <strong>Varshik Mail Program: A Confluence of Tradition and Community Spirit</strong></p>
                                <p>
                                    Every year, the vibrant Beotra community converges in Batala on Kartik Punya day for the Varshik Mail Program. This annual gathering is more than a tradition; it is a celebration of our shared history and a testament to the enduring bonds that unite us. The air is filled with the spirit of camaraderie, as generations come together to strengthen the tapestry of our community.
                                </p>

                                <p>
                                    <strong>Lava Fere: The Sacred Ritual of Marital Union</strong></p>

                                <p>   In the sacred precincts of our Batala temple, the tradition of Lava Fere holds profound significance for the Beotra community. Following the sacred bond of marriage, it is mandatory for every son to take the Lava Fere with his wife in this temple. This ritual is more than a ceremony; it is a solemn pledge to uphold the values passed down through generations and an affirmation of the sanctity of marital union.</p>

                                <p>
                                    <strong>A Platform for Expression: Sharing Wisdom and Sukhan</strong></p>

                                <p>Our community hub serves not only as a place of worship but as a vibrant space where individuals gather to share their thoughts and express themselves. Here, members come to articulate their feelings, share wisdom, and engage in meaningful conversations. It is a space that fosters a sense of community, providing a platform for the exchange of ideas and the preservation of our collective wisdom.</p>

                                <p>
                                    <strong>Nava Purana Anaj and Chawal: Nurturing the Community Spirit</strong></p>

                                <p>In the spirit of unity and support, our community also engages in the beautiful tradition of providing Nava Purana Anaj (new and old grains) and Chawal (rice) at our gatherings. This practice reflects our commitment to ensuring that no member of our community faces scarcity or hunger. It is a gesture that embodies the spirit of togetherness and mutual care that defines the Akhil Beotra Mahajan Sabha.</p>

                                <p>
                                    <strong>Preserving Heritage, Building Community: Join Us in Celebration</strong></p>

                                <p>At Akhil Beotra Mahajan Sabha, we take pride in preserving our rich heritage and fostering a sense of community. Our journey is a testament to the resilience of our founding members and the enduring spirit of the Beotra community. Join us in celebrating our past, present, and future as we continue to uphold the values that define the essence of our community.</p>








                            </div>
                            <div className='col-12 col-md-3'>
                                <p className="d-grid gap-2">
                                    <a href="/Jeevan Sakhi.pdf" target="_blank" className="btn btn-warning btn-lg btn-block" role="button" aria-pressed="true">Download Jeevan Sakhi</a>
                                </p>

                                <p>
                                    <img src={require('../../assets/img/about.jpg')} className="img-fluid" alt="" />
                                </p>

                                <p className="d-grid gap-2">
                                    <Link to="/gallery" className="btn btn-danger btn-lg btn-block" role="button" aria-pressed="true">Visit Image Gallery</Link>
                                </p>
                                <p>
                                    <img src={require('../../assets/img/about-well.jpg')} className="img-fluid" alt="" />
                                </p>


                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout >
    )
}
export default AboutUs