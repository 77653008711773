
import { Home, AboutUs, Gallery, OnlineDonation, ContactUs } from '../containers'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'


const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/about-us',
        element: <AboutUs />
    },
    {
        path: '/gallery',
        element: <Gallery />
    },
    {
        path: '/online-donation',
        element: <OnlineDonation />
    },
    {
        path: '/contact-us',
        element: <ContactUs />
    }
])

function Root() {
    return (
        <RouterProvider router={router} />
    )
}

export default Root