// AudioPlayer.js
import React, { useEffect, useState } from 'react';

const AudioPlayer = ({ playAudio }: any) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const audioFile = process.env.PUBLIC_URL + '/jaikare.mp3';

    const togglePlay = () => {
        const audio: any = document.getElementById('audio');

        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }

        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        if (playAudio) {
            togglePlay()
        }
    }, [playAudio])

    return (
        <div style={{ display: "none" }}>
            <audio id="audio" src={audioFile}>
                Your browser does not support the audio tag.
            </audio>

        </div>
    );
};

export default AudioPlayer;
