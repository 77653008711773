import { useEffect } from 'react'
import AOS from "aos";

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'

import './assets/css/main.css'
import "aos/dist/aos.css";

import Root from './routes';

function App() {

  useEffect(() => {
    AOS.init({
      easing: 'ease-out-quad',
      duration: 1000,
    });

    // let preLoader = document.getElementById("preloader")

    // if (preLoader) {
    //   console.log(preLoader)
    //   setTimeout(() => {
    //     document.getElementById("preloader")?.remove()
    //   }, 2000)
    // }

  }, [])

  return (
    <Root />
  );
}

export default App;
