import { useEffect } from "react"
import Layout from "../layout"
import { Link } from "react-router-dom"

function OnlineDonation() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])
    return (
        <Layout activePage='donation'>
            <main className="page">
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Online Donation</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="about">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-6'>


                                <p>
                                    Please scan this QR code to pay from UPI ID (Google Pay, Phone Pay, Amazon Pay etc.)</p>

                                <p>
                                    <p>
                                        <img src={require('../../assets/img/qr-code.jpg')} className="img-fluid" alt="" />
                                    </p>
                                </p>








                            </div>
                            <div className='col-12 col-md-6'>
                                <p className="d-grid gap-2">
                                    For direct bank transfer please use the below bank accounts for the donations.
                                </p>


                                <p className="mt-5 mb-5 px-5">
                                    <p><strong>Bank Name:</strong> Kotak Mahindra Bank, Batala</p>
                                    <p><strong>A/C No:</strong> 9814242542</p>
                                    <p><strong>IFSC:</strong> KKBK0004102</p>
                                </p>


                                <p className="mt-3 mb-5 px-5">
                                    <p><strong>Bank Name:</strong> Canara Bank, Batala</p>
                                    <p><strong>A/C No:</strong> 2085101027858</p>
                                    <p><strong>IFSC:</strong> CNRB0002085</p>
                                </p>


                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout >
    )
}
export default OnlineDonation