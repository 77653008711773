import { Header, Footer } from '../../components'


function Layout({ children, activePage }: any) {
    return (
        <>
            <Header activePage={activePage} />
            {children}
            <Footer />
        </>
    )
}

export default Layout