import Logo from '../../assets/img/main-logo.png'
import { Link } from 'react-router-dom'
import AudioPlayer from '../audio';
import { useState } from 'react';
export default function Header({ activePage }: any) {

    const [playAudio, setPlayAudio] = useState(false);

    const toggeleNav = () => {
        let body = document.body;
        body.classList.toggle("mobile-nav-active")
        let menuToggle = document.querySelector(".mobile-nav-show")
        let menuClose = document.querySelector(".mobile-nav-hide")
        menuToggle?.classList.add("mobile-nav-hide")
        menuToggle?.classList.add("d-none")
        menuToggle?.classList.remove("mobile-nav-show")

        menuClose?.classList.add('mobile-nav-show')
        menuClose?.classList.remove("mobile-nav-hide")
        menuClose?.classList.remove("d-none")

    }

    return (
        <header id="header" className="header  d-flex align-items-center flex-column">
            <div className="container-fluid border-bottom">
                <div className="container d-flex align-items-center justify-content-between top-bar">

                    <div className="call-us d-flex align-items-center justify-content-start">
                        <div className="call-list-item">
                            <i className="bi bi-telephone-fill"></i> <a href="tel:9914355551">9914355551</a>
                        </div>
                        {/* <div className="call-list-item">
                            <a href="tel:9814242542">9814242542</a>
                        </div>
                        <div className="call-list-item">
                            <a href="tel:9814657672">9814657672</a>
                        </div>
                        <div className="call-list-item">
                            <a href="tel:9217972176">9217972176</a>
                        </div> */}
                    </div>
                    <a className="btn-book-a-table" href="#" onClick={(e) => { e.preventDefault(); setPlayAudio(true) }}><i className="bi bi-volume-down"></i> Listen Jaikare</a>
                    <AudioPlayer playAudio={playAudio} />


                </div>
            </div>
            <div className="container d-flex align-items-center justify-content-between py-3">

                <div className="d-flex align-items-center me-auto me-lg-0">
                    <Link to="/" className="logo">
                        <img src={Logo} alt="" />
                        {/* Akhil Beotra Mahajan Sabha */}

                    </Link>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li><Link to="/" className={(activePage == 'home') ? "active" : ""}>Home</Link></li>
                        <li><Link to="/about-us" className={(activePage == 'about') ? "active" : ""}>About Us</Link></li>
                        <li><Link to="/gallery" className={(activePage == 'gallery') ? "active" : ""}>Gallery</Link></li>
                        <li><Link to="/online-donation" className={(activePage == 'donation') ? "active" : ""}>Online Donation</Link></li>
                        <li><Link to="/contact-us" className={(activePage == 'contact') ? "active" : ""}>Contact Us</Link></li>

                    </ul>
                </nav>


                <i onClick={() => { toggeleNav() }} id="menu-toggle" className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
                <i onClick={() => { toggeleNav() }} id="menu-close" className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

            </div>
        </header>
    )
}