import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
    original: string;
}

export const images: CustomImage[] = [
    {
        src: require('../../assets/img/about-2.jpg'),
        original: require('../../assets/img/about-2.jpg'),
        width: 500,
        height: 600
    },
    {
        src: require('../../assets/img/gallery/01.jpg'),
        original: require('../../assets/img/gallery/01.jpg'),
        width: 500,
        height: 600
    },
    {
        src: require('../../assets/img/gallery/02.jpg'),
        original: require('../../assets/img/gallery/02.jpg'),
        width: 500,
        height: 600
    },
    {
        src: require('../../assets/img/gallery/03.jpg'),
        original: require('../../assets/img/gallery/03.jpg'),
        width: 500,
        height: 600
    },
    {
        src: require('../../assets/img/gallery/04.jpg'),
        original: require('../../assets/img/gallery/04.jpg'),
        width: 500,
        height: 600
    },
    {
        src: require('../../assets/img/gallery/05.jpg'),
        original: require('../../assets/img/gallery/05.jpg'),
        width: 500,
        height: 600
    },
    {
        src: require('../../assets/img/gallery/06.jpg'),
        original: require('../../assets/img/gallery/06.jpg'),
        width: 500,
        height: 600
    },
    {
        src: require('../../assets/img/gallery/07.jpg'),
        original: require('../../assets/img/gallery/07.jpg'),
        width: 500,
        height: 600
    },
];