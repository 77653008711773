import { useState } from 'react';
import Slider from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

export default function Carousel({ items }: any) {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: any) => {
        setIndex(selectedIndex);
    };

    return (

        <Slider indicators={false} controls={false} style={{ backgroundColor: "#f4f4f4" }} activeIndex={index} onSelect={handleSelect} fade>

            {items.map((el: any, key: any) => {
                return (
                    <Slider.Item key={key} interval={el.interval}>
                        {el.item}

                        <Link to="/online-donation" className="btn btn-danger online-donation-btn">*Online Donation</Link>
                        <Slider.Caption style={{ backgroundColor: "#8d040469" }}>
                            <div data-aos="animated bounceInLeft">
                                <h3>Darbar Sati Mata Sheela Devi and Bala Dev Maharaj Ji</h3>
                            </div>
                        </Slider.Caption>
                    </Slider.Item>
                )
            })}

        </Slider>
    )
}